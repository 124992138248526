import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  loginUser,
  sendErrorMessage,
  sendSuccessMessage,
  tokenUserLogin
} from '../services/userServices'
import cookie from 'react-cookies'
import { useDispatch, useSelector } from 'react-redux'
import { saveUserDetails } from '../../store/actions/userAction'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getToken } from '../../firebase'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './Login.css'
import axios from 'axios'
// import { toast } from "material-react-toastify";
const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const [forgetPassword, setForgetPassword] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [passId, setPassId] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const PosLogin = async data => {
    let obj = {
      username: data?.username,
      password: data?.password,
      source: 'web'
    }
    let headers = {
      'Content-Type': 'multipart/form-data'
    }

    const res = await axios.post(
      'https://uatbpm.expertcover.in/api/v1/employee/User',
      obj,
      { headers: headers }
    )
    if (res?.data?.status) {
      toast.success('Login Successful')
      window.location.href = res?.data?.data?.redirection_url
    } else {
      alert('Invalid Username or Password')
    }
  }

  const handleBecomePos = () => {
    window.location.href = 'https://uat.expertcover.in/becomepos'
  }

  const handleForgetPassword = async val => {
    setLoading(true)
    let headers = {
      'Content-Type': 'multipart/form-data'
    }
    let obj = {
      Email: val?.Email,
      source: 'web',
      user_type: 2
    }
    const response = await axios.post(
      'https://uatbpm.expertcover.in/api/v1/employee/Employee/send_one_time_password',
      obj,
      { headers: headers }
    )

    console.log(response, 'OTP response')
    if (response?.data?.status) {
      setForgetPassword(false)
      setResetPassword(true)
      setPassId(response?.data?.id)
      toast.success(response?.data?.message)
    } else {
      toast.error(response?.data?.message)
    }
    setLoading(false)
  }

  const handleResetPassword = async val => {
    setLoading(true)
    let headers = {
      'Content-Type': 'multipart/form-data'
    }
    let obj = {
      id: passId,
      otp: val?.otp,
      pass: val?.pass,
      confirm_pass: val?.confirm_pass,
      user_type: 2
    }
    const resetRes = await axios.post(
      'https://uatbpm.expertcover.in/api/v1/employee/Employee/update_password',
      obj,
      { headers: headers }
    )

    console.log(resetRes, 'OTP response')
    if (resetRes?.data?.status) {
      setForgetPassword(false)
      setResetPassword(false)
      toast.success(resetRes?.data?.message)
    } else {
      toast.error(resetRes?.data?.message)
    }
    setLoading(false)
  }

  const handleBackToLogin = () => {
    setForgetPassword(false)
    setResetPassword(false)
  }

  return (
    <>
      <div className='container'>
        <div className='forms-container'>
          <div className='signin-signup'>
            <div className='logo'>
              <img src='assets/images/logo.png' alt='' />
            </div>

            {/* Login */}
            {forgetPassword === false && resetPassword === false && (
              <form onSubmit={handleSubmit(PosLogin)} className='sign-in-form'>
                <h2 className='title'>POSP Sign in</h2>
                <div className='input-field'>
                  <i className='fas fa-user'></i>
                  <input
                    type='text'
                    placeholder='Username'
                    name='username'
                    id='username'
                    {...register('username', {
                      required: 'Username is required'
                    })}
                  />
                  <p className='mb-0 f-error'>{errors?.username?.message}</p>
                </div>
                <div className='input-field'>
                  <i className='fas fa-lock'></i>
                  <input
                    type='password'
                    placeholder='Password'
                    name='pass'
                    {...register('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters'
                      }
                    })}
                  />
                  <p className='mb-0 f-error'>{errors?.password?.message}</p>
                </div>
                <input type='submit' value='Login' className='btn solid' />

                <p
                  className='social-text'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setForgetPassword(true)}
                >
                  Forgot Password?
                </p>
              </form>
            )}

            {/* Forget Password */}
            {forgetPassword && (
              <form
                onSubmit={handleSubmit(handleForgetPassword)}
                className='sign-in-form'
              >
                <h2 className='title'>Forget Password</h2>
                <div className='input-field'>
                  <i className='fas fa-user'></i>
                  <input
                    type='text'
                    placeholder='Email'
                    name='Email'
                    id='Email'
                    {...register('Email', {
                      required: 'Email is required'
                    })}
                  />
                  <p className='mb-0 f-error'>{errors?.Email?.message}</p>
                </div>

                <button type='submit' className='btn solid'>
                  Send OTP
                </button>

                <p
                  className='social-text'
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleBackToLogin(true)}
                >
                  Back to login
                </p>
              </form>
            )}

            {/* Reset Password */}
            {resetPassword && (
              <form
                onSubmit={handleSubmit(handleResetPassword)}
                className='sign-in-form'
              >
                <h2 className='title'>Reset Password</h2>
                <div className='input-field'>
                  <i className='fas fa-user'></i>
                  <input
                    type='number'
                    placeholder='OTP'
                    name='otp'
                    id='otp'
                    {...register('otp', {
                      required: 'otp is required'
                    })}
                  />
                  <p className='mb-0 f-error'>{errors?.otp?.message}</p>
                </div>

                <div className='input-field'>
                  <i className='fas fa-user'></i>
                  <input
                    type='password'
                    placeholder='New Password'
                    name='pass'
                    id='pass'
                    {...register('pass', {
                      required: 'password is required'
                    })}
                  />
                  <p className='mb-0 f-error'>{errors?.pass?.message}</p>
                </div>

                <div className='input-field'>
                  <i className='fas fa-user'></i>
                  <input
                    type='password'
                    placeholder='Confirm Password'
                    name='confirm_pass'
                    id='confirm_pass'
                    {...register('confirm_pass', {
                      required: 'password is required'
                    })}
                  />
                  <p className='mb-0 f-error'>
                    {errors?.confirm_pass?.message}
                  </p>
                </div>

                <button type='submit' className='btn solid'>
                  Submit
                </button>
              </form>
            )}
          </div>
        </div>

        <div className='panels-container'>
          <div className='panel left-panel'>
            <div className='content'>
              {/* <h3>New here ?</h3>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Voluptas rerum nulla eius?
              </p> */}
              <img src='assets/images/logo.png' alt='' className='logo_img' />
              <button
                onClick={() => handleBecomePos()}
                className='btn transparent'
                id='sign-up-btn'
              >
                Become A POS
              </button>
            </div>

            <img src='assets/images/logobg2.png' className='image' alt='' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
