export const LOGGED_USER_DETAILS = "LOGGED_USER_DETAILS";
export const QUICK_QUOTE_PAYLOAD = "QUICK_QUOTE_PAYLOAD";
export const QUICK_QUOTE_RESULTS = "QUICK_QUOTE_RESULTS";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const SET_LEAD_ID = "SET_LEAD_ID";

export const RESET_QUICK_QUOTE_PAYLOAD = "RESET_QUICK_QUOTE_PAYLOAD";
export const RESET_QUICK_QUOTE_RESULTS = "RESET_QUICK_QUOTE_RESULTS";
export const RESET_SELECTED_PLAN = "RESET_SELECTED_PLAN";

export const SUBMIT_OFFLINE = "SUBMIT_OFFLINE";
