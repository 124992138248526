import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import CheckAuth from "./CheckAuth";
function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<CheckAuth />}>
          <Route path="/" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;
